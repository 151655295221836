<template>
    <div class="browse">
        <a-tabs @change="tabChange" v-model="curTab" :animated="false">
            <template v-for="item in tabList">
                <a-tab-pane :key="item.value" :tab="item.name"  >
                    <Post v-if="item.value == 0 && item.value == curTab" :tabId="item.value" :keyword="item.key" :ref="item.ref"/>
                    <Post v-if="item.value == 1 && item.value == curTab" :tabId="item.value" :keyword="item.key" :ref="item.ref"/>
                </a-tab-pane>
            </template>
            <div class="search" slot="tabBarExtraContent">
                <a-input-search 
                    v-for="item in tabList"
                    :key="item.value"
                    placeholder="请输入检索内容" 
                    v-model="item.key"  
                    style="width: 300px" 
                    v-show="item.value == curTab"
                    @search="search(item.ref)"
                />
            </div>
        </a-tabs>
    </div>
</template>
<script>
import Post from '~/components/Personal/Release/Post'
export default {
    metaInfo() {
		return {
			title: '浏览记录-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '浏览记录-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '浏览记录-个人中心-文物出版社'
                }
            ]
		}
	},
    components:{
        Post
    },
    data(){
        return{
            tabList:[
                {
                    value:0,
                    name:'已审核',
                    ref:'audit',
                    key:''
                },
                {
                    value:1,
                    name:'待审核',
                    ref:'unaudit',
                    key:''
                },
                // {
                //     value:2,
                //     name:'帖子'
                // }
            ],
            curTab:0,
            value:''            
        }
    },
    methods:{
        tabChange(activeKey){
            this.curTab = activeKey
            console.log(activeKey);
        },
        search(name){
            this.$refs[name][0].onSearch()
        }
    }
}
</script>
<style scoped lang="less">
.browse{
    padding-top: 20px;
}
</style>