<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='80'>
                    <col width='360'>
                    <col width='100'>
                    <col width='200'>
                    <col width='170'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th>序号</th>
                        <th>标题</th>
                        <th>回复</th>
                        <th>发帖时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="( item , index ) in data" :key="item.Id">
                        <td class="index"><span >{{index + 1}}</span></td>
                        <td>
                            <div class="tdtitle" v-if="tabId == 0" @click="goDetail(item)">{{item.Title}}</div>
                            <div class="tdtitle" v-else @click="goEdit(item)">{{item.Title}}</div>
                        </td>
                        <td class="readTime">
                            {{item.Reply}}
                        </td>
                        <td class="readTime">
                            {{item.CreateTime}}
                        </td>
                        
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="goEdit(item)" v-if="item.ParentId == 0">编辑</span>
                                <a-popconfirm title="删除该帖子？" ok-text="是" cancel-text="否" @confirm="deleteRecord(item)">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
            :current="params.pageIndex"
        />
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetList  } from '@/api/visit'
import { GetMyPostList  } from '@/api/user'
import { Del } from '@/api/forum'
export default {
    props:['keyword','tabId'],
    components:{
        Pagination
    },
    data(){
        return{
            
            data:[],
            params:{
                pageIndex:1,
                pageSize:10,
                isAudit:'1',
                key:''
            },
            total:0,
            spinning:false,
        }
    },
    watch:{
        keyword:{
            handler(newVal,oldVal){
                this.params.key = newVal
            },
            immediate:true
        }
    },
    methods:{
        pageChange(page){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        onSearch(){
            this.params.pageIndex = 1
            this.getList()
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetMyPostList(this.params)
            this.data = data 
            this.total = total
            this.spinning = false
        },
        goDetail(item){
            this.$router.push({name:'forum-info',params:{id:item.Id}})
        },
        goEdit(item){
            if(item.ParentId !== 0) return
            this.$router.push({name:'publish',query:{id:item.Id}})
        },
        deleteRecord(item){
            this.spinning = true
            Del({id:item.Id}).then(res => {
                this.$message.success('删除成功')
                this.spinning = false
                this.getList()
            }).catch(e => {
                this.spinning = false
            })
        }
    },
    created(){
        if(this.tabId == 0){
            this.params.isAudit = '1'
        }else{
            this.params.isAudit = '0'
        }
        console.log(1234);
        this.getList()
    }
}
</script>
<style lang="less" scoped>

.tdtitle{
    cursor: pointer;
    // text-align: center;
    &:hover{
        color: #f6a75c;
    }
}
.table .handle_inner span:last-of-type{
    color: #888888;
    &:hover{
        color: #f6a75c;
    }
}
</style>